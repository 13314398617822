<template>
  <div class="do_keep">
    <back-step
      :goList="[
        {
          url: '/cases',
          name: $t('casesDetail.casesChildren.common.bl'),
        },
        {
          url: `/cases/detail?caseId=${$route.query.caseId}&curePlanId=${$route.query.curePlanId}`,
          name: $t('casesDetail.casesChildren.common.blxq'),
        },
      ]"
      :currentTxt="$t('casesDetail.casesChildren.casesDoKeep.zbc')"
    />
    <div class="plan_head">
      <span class="plan_h_strong"
        >{{ $t("casesDetail.casesChildren.casesDoKeep.blh") }}：{{
          casesInfo.caseNumber
        }}</span
      >
      <span class="plan_h_sp">{{ casesInfo.clinicName }}</span>
      <span class="plan_h_sp">{{ casesInfo.productName }}</span>
    </div>
    <div class="cases_d_box">
      <div class="cases_d_con">
        <div class="cases_d_left">
          <cases-user-msg v-if="commonCaseDetail.state !== 'loading'" />
        </div>
        <div class="cases_d_right">
          <ul class="braces_from">
            <li class="f_single">
              <div class="f_single_left">
                {{ "定制保持器:" }}
              </div>
              <div class="f_single_right">
                <div
                  :class="[nowChoosenType == 0 && 'active_btn', 'custom_btn']"
                  @click="changeNowChoosenType"
                >
                  最后一步订做保持器
                </div>
                <div
                  :class="[nowChoosenType == 1 && 'active_btn', 'custom_btn']"
                  @click="toScan"
                >
                  提交模型订做保持器
                </div>
                <p class="done_tips" v-if="rescanFlag !== '0'">
                  {{ $t("casesDetail.casesChildren.casesDoKeep.ycxsm") }}
                </p>
              </div>
              <!-- <div class="f_single_left">
                {{ $t('casesDetail.casesChildren.casesDoKeep.djf') }}:
              </div>
              <div class="f_single_right">
                <div class="right_iup" v-if="rescanFlag === '0'">
                  <input type="text" v-model="doKeepForm.set">
                </div>
                <div class="right_iup" v-else>
                  <input type="text" style="color: #999;" :value="$t('casesDetail.casesChildren.casesDoKeep.ycxsm')"
                         disabled>
                </div>
                <p class="single_r_p" @click="goPage('/cases/casesToScan')">
                  <span class="r_p_icon"></span>
                  {{ $t('casesDetail.casesChildren.casesDoKeep.cxsm') }}
                </p>
              </div> -->
            </li>
            <li class="f_single">
              <div class="f_single_left">
                {{ $t("casesDetail.casesChildren.casesDoKeep.cz") }}:
              </div>
              <div class="f_single_right">
                <select v-model="doKeepForm.colorId">
                  <option value="" disabled style="color: #999999">
                    {{ $t("casesDetail.casesChildren.casesDoKeep.qxz") }}
                  </option>
                  <option
                    :value="item.key"
                    :key="item.item"
                    v-for="item in remainColor"
                  >
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </li>
          </ul>
          <p class="warn_tips">如果非最后一步制作保持器（包含特殊情况），请联系客服人员！</p>
        </div>
      </div>
      <div class="cases_d_foo">
        <div class="submit_btn curp flex-x-y-c fz16" @click="doKeep">
          {{ $t("casesDetail.casesChildren.common.qr") }}
        </div>
        <div
          class="back_btn curp flex-x-y-c mr36 fz16"
          @click="goPage('/cases/detail')"
        >
          {{ $t("casesDetail.casesChildren.common.fh") }}
        </div>
      </div>
    </div>
    <confirm-modal
      :confirmObj="{
        tipText: $t('casesDetail.casesChildren.casesDoKeep.qrjx'),
        backText: $t('casesDetail.casesChildren.casesDoKeep.qx'),
        okText: $t('casesDetail.casesChildren.casesDoKeep.qd'),
      }"
      @confirmSubmit="confirmSubmit"
      @confirmHide="confirmHide"
      v-show="isShow"
    />
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
import BackStep from "../childrenPublic/backStep";
import CasesUserMsg from "../childrenPublic/casesUserMsg";
import FullLoading from "components/full-loading/full-loading";
import ConfirmModal from "components/confirm-modal/confirm";
import {
  curePlanRemain,
  casesDetail,
  PublicPlasticSheetList,
} from "common/api/cases";
import { getDictList } from "common/api/public";
import { notifyMsg } from "common/js/util";
import { extend } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      isShow: false,
      casesInfo: {},
      doKeepForm: {
        set: "",
        colorId: "",
      },
      remainColor: [],
      rescanFlag: "0",
      nowChoosenType: 0,
    };
  },
  computed: {
    ...mapGetters({
      commonCaseDetail: "getCommonCaseDetail", //病例详情
    }),
  },
  methods: {
    ...mapActions({
      getCommonCaseDetail: "actionGetCommonCaseDetail", //病例详情
    }),
    goBack() {
      window.history.go(-1);
    },
    changeNowChoosenType() {
      this.nowChoosenType = 0;
      this.rescanFlag = '0'
    },
    toScan() {
      this.nowChoosenType = 1
      this.$router.push({
        path: '/cases/casesToScan',
        query: extend({}, this.$route.query),
      })
    },
    goPage(url) {
      this.$router.push({
        path: url,
        query: extend({}, this.$route.query),
      });
    },
    doKeep() {
      const { colorId, set } = this.doKeepForm;
      if (!colorId) {
        return notifyMsg(
          this,
          "warning",
          this.$t("casesDetail.casesChildren.casesDoKeep.qxzcz")
        );
      }
      this.isShow = true;
    },
    confirmSubmit() {
      const id = this.$route.query.curePlanId;
      if (id) {
        this.isLoading = true;
        curePlanRemain({
          id,
          colorId: this.doKeepForm['colorId'],
        })
          .then(() => {
            this.isShow = false;
            this.isLoading = false;
            notifyMsg(
              this,
              "success",
              this.$t("casesDetail.casesChildren.common.czcg")
            ).then(() => {
              this.goPage(
                `/cases/detail?caseId=${this.$route.query.caseId}&curePlanId=${this.$route.query.curePlanId}`
              );
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.isShow = false;
          });
      } else {
        notifyMsg(
          this,
          "error",
          this.$t("casesDetail.casesChildren.common.czsb")
        );
      }
    },
    confirmHide() {
      this.isShow = false;
    },
  },
  components: {
    BackStep,
    CasesUserMsg,
    FullLoading,
    ConfirmModal,
  },
  created() {
    document.title = this.$t("casesDetail.casesChildren.casesDoKeep.zbc");
    const casesId = this.$route.query.caseId;
    this.getCommonCaseDetail(casesId).then((data) => {
      const { productName, clinicName, caseNumber, curePlanInfo } = data;
      this.rescanFlag = curePlanInfo.rescanFlag || "0";
      if (this.rescanFlag === '0') {
        this.nowChoosenType = 0
      } else {
        this.nowChoosenType = 1
      }
      this.casesInfo = {
        caseNumber,
        clinicName,
        productName,
      };
      const plasticProductId = data['bindProductId'] !== "0" ? data['bindProductId'] : data['productId']
      PublicPlasticSheetList({ productId: plasticProductId, stage: "D" }).then(
        (data) => {
          this.remainColor = data.map((item) => ({
            key: item.id,
            value: item.name,
          }));
        }
      );
    });
  },
};
</script>

<style scoped lang="scss">
.do_keep {
  width: 13.44rem;
  margin: 0 auto;
  padding-top: 50px;

  .plan_head {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;

    .plan_h_strong {
      font-size: 0.2rem;
      color: $main_theme_color_333;
    }

    .plan_h_sp {
      padding-left: 0.1rem;
      border-left: 0.01rem solid #666666;
      margin-left: 0.1rem;
      font-size: 16px;
      color: $main_theme_color_333;
    }
  }

  .cases_d_box {
    background-color: #fff;
    border-radius: 0.1rem;
    padding: 0.3rem;
    height: 344px;

    .cases_d_con {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.67rem;

      .braces_from {
        padding-top: 0.12rem;

        .f_single {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          font-size: 0.14rem;

          .f_single_left {
            margin-right: 0.06rem;
            font-size: 0.16rem;
            width: 0.92rem;
            color: $main_theme_color_666;
            text-align: right;
            white-space: nowrap;

            span {
              color: #ff6a08;
            }
          }

          .f_single_right {
            border-radius: 0.02rem;
            min-height: 0.4rem;
            color: $main_theme_color_333;
            font-size: 0.14rem;
            display: flex;
            align-items: center;
            flex: 1;
            position: relative;
            display: flex;
            justify-content: flex-end;

            .custom_btn {
              padding: 10px 16px;
              border: 1px solid #bbbbbb;
              border-radius: 6px;
              cursor: pointer;

              &:first-child {
                margin-right: 14px;
              }
            }

            .done_tips {
              position: absolute;
              bottom: -30px;
              right: 0;
              color: $main_theme_color;
            }

            .active_btn {
              background-color: $main_theme_color;
              color: #333;
              border-color: $main_theme_color;
            }

            select {
              width: 2.74rem;
              height: 0.42rem;
              border: none;
              background-color: #f4f4f4;
              padding: 0.1rem 0.19rem;
              box-sizing: border-box;
            }

            .right_iup {
              width: 2.74rem;
              height: 0.4rem;
              padding: 0.1rem 0.2rem;
              box-sizing: border-box;
              background-color: #f4f4f4;

              input {
                width: 100%;
                height: 100%;
              }
            }

            .right_area {
              background-color: #f4f4f4;
              padding: 0.1rem;
              height: 1.14rem;
              border-radius: 0.08rem;
              width: 5.6rem;

              textarea {
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
              }
            }

            .single_r_p {
              display: flex;
              align-items: center;
              color: $main_theme_color;
              margin-left: 0.19rem;
              cursor: pointer;
              white-space: nowrap;

              .r_p_icon {
                display: inline-block;
                width: 0.16rem;
                height: 0.16rem;
                margin-right: 0.05rem;
                background-size: 100% 100%;
                background-image: url("/static/images/web_scanning.png");
              }
            }
          }
        }
      }

      .warn_tips {
        color: #999;
        font-size: 14px;
        text-align: right;
      }
    }

    .cases_d_foo {
      display: flex;
      align-items: center;
      flex-flow: row-reverse;

      .back_btn {
        width: 176px;
        height: 42px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #bbbbbb;
      }

      .submit_btn {
        width: 176px;
        height: 42px;
        background: $main_theme_color;
        border-radius: 6px;
      }
    }
  }
}
</style>
